// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// Fix mobile close toggle position.
.navbar-toggler-close {
    top: 7px;
}

.lead {
    font-size: 1.10rem;
}

.bg-divider-custom {
    fill: #022967 !important;
}

.bg-info-custom {
    background-color: #022967 !important;
}

.text-color-custom {
    color: #d0d0d0 !important;
}

.no-blog-btn {
    color: #022967;
    font-weight: 600;
    background: none;
    border: none;
}

@media (min-width: 576px) {
    .ms-sm-4 {
        margin-left: 1.5rem !important;
    }
}

// Spacing for member cards on ueber uns page

.pb-10 {
    padding-bottom: 4.5rem !important;
}

.pt-10 {
    padding-top: 4.5rem !important;
}

// Spacing for the title

.title-spacing {
    padding-top: 10rem !important;
    padding-bottom: 1rem !important;
}

// Blue underline

.main-title {
    text-shadow: rgb(0, 0, 0) 0px 4px 8px;
}

.main-title:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    margin-top: -10px;
    border-bottom: 15px solid $primary;
}

// Resizing the underline

@media screen and (max-width: 991px) {
    .main-title:after {
        width: 20%;
    }
}

@media screen and (max-width: 767px) {
    .main-title:after {
        width: 35%;
    }
}

// Blockquote Newsletter
.blockquote-newsletter blockquote {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    border-left: 3px solid black;
    border-radius: 0px;
    margin: 2rem 0 2rem 0;
    h5 {
        margin-bottom: 0.5rem;
    }
    p {
        margin-bottom: 0;
    }
}
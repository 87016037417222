// 
// 
// components.scss
//
//


// Required Components
//
// These partials are essential for theme styling

@import "components/required/background-images";
@import "components/required/buttons";
@import "components/required/card";
@import "components/required/dropdowns";
@import "components/required/footers";
@import "components/required/grid";
@import "components/required/images";
@import "components/required/lists";
@import "components/required/navbar";
@import "components/required/navs";
@import "components/required/svg";
@import "components/required/typography";
@import "components/required/utilities";

// Optional Components
//
// These partials can be included and excluded as required

@import "components/plugins/aos";
@import "components/optional/accordions";
@import "components/optional/articles";
@import "components/optional/avatars";
@import "components/optional/badge";
@import "components/optional/breadcrumb";
@import "components/optional/countdowns";
@import "components/optional/decorations";
@import "components/optional/dividers";
@import "components/optional/fancybox";
// @import "components/optional/flatpickr";
// @import "components/optional/flickity";
@import "components/optional/forms";
@import "components/optional/headers";
@import "components/optional/hover-effects";
@import "components/optional/icons";
@import "components/optional/image-collage";
// @import "components/optional/ion-rangeslider";
@import "components/plugins/jarallax";
@import "components/optional/list-group";
// @import "components/optional/maps";
@import "components/optional/modals";
// @import "components/optional/plyr";
// @import "components/optional/prism";
// @import "components/optional/page-transitions";
@import "components/optional/pagination";
@import "components/optional/popovers";
@import "components/optional/pricing";
@import "components/optional/processes";
@import "components/optional/progress";
@import "components/optional/reading-position";
@import "components/optional/tables";
@import "components/optional/tooltips";
// @import "components/optional/twitterfetcher";
// @import "components/optional/wizard";
@import "components/optional/ie-fixes";

// Theme Demonstration Components
//
// These partials are not required in production sites

@import "components/optional/docs";
